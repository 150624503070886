import { useTranslation } from 'react-i18next'
import Typography from '~/components/Typography'
import {
  CHECK_UP_PRICE_IN_CENTS_HT,
  CHECK_UP_PRICE_IN_CENTS_TTC
} from '~/constants/offer'
import { formatCentsToEuros } from '~/helpers/number'
import { useLocale } from '~/hooks/useLocale'

type AsideResumePriceProps = {
  isExcludingTaxVisible: boolean
}

const AsideResumePrice = ({ isExcludingTaxVisible }: AsideResumePriceProps) => {
  const { t } = useTranslation()
  const locale = useLocale()

  return (
    <div>
      <div className="flex items-center gap-x-2 flex-wrap">
        <Typography component="var" variant="h3" noWrap>
          {formatCentsToEuros(
            isExcludingTaxVisible
              ? CHECK_UP_PRICE_IN_CENTS_HT
              : CHECK_UP_PRICE_IN_CENTS_TTC,
            {
              minimumFractionDigits: 0,
              locale
            }
          )}
        </Typography>
        <Typography component="span" color="shy">
          {t(isExcludingTaxVisible ? 'common:excluTax' : 'common:incluTax')}
        </Typography>
      </div>
      {isExcludingTaxVisible ? (
        <span className="flex gap-x-2 flex-wrap items-center">
          <Typography component="var" variant="h5" noWrap color="shy">
            {formatCentsToEuros(CHECK_UP_PRICE_IN_CENTS_TTC, {
              minimumFractionDigits: 0,
              locale
            })}
          </Typography>
          <Typography component="span" color="shy" variant="mention">
            {t('common:incluTax')}
          </Typography>
        </span>
      ) : null}
    </div>
  )
}

export default AsideResumePrice
